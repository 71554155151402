















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import DynamicForm from "@/components/DynamicForm.vue";
import AlwaysAvailableQuestionnaireSubmissionForm from "@/components/forms/AlwaysAvailableQuestionnaireSubmissionForm.vue";
import {
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import ICrudClient from "@/lib/ICrudClient";
import { Project } from "@/data/models/Projects";
import { saveAs } from "file-saver";

@Component({
  components: {
    ModelTable,
    DynamicForm,
    AlwaysAvailableQuestionnaireSubmissionForm
  }
})
export default class AlwaysAvailableQuestionnaireSubmissionTable extends Vue {
  private provider: DataProvider<QuestionnaireSubmission> | null = null;

  @Prop()
  public crud!: ICrudClient<QuestionnaireSubmission>;

  @Prop()
  public columns!: any[];

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  private questionnaires: any = [];
  private selected: any[] = [];
  private showQuestionnaireDialog = false;

  private selectedQuestionnaire: any = null;
  private previousSubmission: any = null;

  private async mounted() {
    this.provider = this.$service.providers.alwaysAvailableQuestionnaireSubmissions;
    this.questionnaires = (
      await this.$service.providers.questionnaires.fetchItemsAsync({
        columnFilters: [
          {
            column: "QuestionnaireType",
            value: `'AlwaysAvailable'`,
            op: "Equals"
          }
        ]
      })
    ).items;
  }
  private reset() {
    this.showQuestionnaireDialog = false;
    this.selectedQuestionnaire = null;
    this.previousSubmission = null;
    (this.$refs.modelTable as any).refresh();
  }
  private async editSubmission(q: any) {
    this.selectedQuestionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
      q.questionnaireId
    );
    this.previousSubmission = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
      q.id
    );
    this.showQuestionnaireDialog = true;
  }
  private async showQuestionnaire(q: any) {
    const quest = await this.$service.providers.questionnaires.fetchItemAsync(
      q.id
    );
    this.selectedQuestionnaire = quest;
    this.previousSubmission = null;
    this.showQuestionnaireDialog = true;
  }
  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }
    private async downloadAnswers(item: any) {
      const utc = new Date().toJSON().slice(0, 10);
      try {
      saveAs(await this.$service.downloadQuestionnaireAnswers(item.id), 
      `${item.questionnaire?.name}_${utc}.xlsx`);
    } catch (e) {
      console.error(e);
    }
  }
}
