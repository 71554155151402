import { render, staticRenderFns } from "./AlwaysAvailableQuestionnaireSubmissionTable.vue?vue&type=template&id=da961f24&scoped=true&"
import script from "./AlwaysAvailableQuestionnaireSubmissionTable.vue?vue&type=script&lang=ts&"
export * from "./AlwaysAvailableQuestionnaireSubmissionTable.vue?vue&type=script&lang=ts&"
import style0 from "./AlwaysAvailableQuestionnaireSubmissionTable.vue?vue&type=style&index=0&id=da961f24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da961f24",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTd from 'quasar/src/components/table/QTd.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTooltip,QBtnDropdown,QList,QItem,QItemSection,QAvatar,QItemLabel,QDialog,QTd});qInstall(component, 'directives', {ClosePopup});
